import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import './status.css';

type statusProps = {
  markerImg?: string
  isLoading: boolean
  isScanning: boolean
  isProcessing: boolean
  isDemo: boolean
  needsTap: boolean
  isError: string
  contentVisible: boolean
  allowUnanchored: boolean
  isPublished?: boolean
  progress?: number
  hasTapped?: () => void;
  viewExp?: () => void;
}

const StatusOverlay: React.FC<statusProps> = (({ markerImg, isLoading, needsTap, isScanning, allowUnanchored, isProcessing, isError, contentVisible, isPublished, isDemo, progress = 0, hasTapped = () => {}, viewExp }) => {
  // const firstImageOpacity = progress <= 1 ? 0 : progress >= 60 ? 1 : (progress - 40) / 100
  const [progressLate, setProgressLate] = useState(0)
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setProgressLate(progress)
    }, 300);
    if(progress >= 100) {
      setTimeout(() => {
        setHasLoaded(true)
      }, 300);
    }
  }, [progress])

  const handleTap = () => {
    if(needsTap && !isLoading) {
      hasTapped()
    }
  }

  return (
    <>
      {isError === '' && (isLoading || needsTap)
      ? (isPublished || isDemo) &&
        <div className="mindar-ui-overlay mindar-ui-loading">
          <div className="stat-window">
            {/* <img className={`gif1  fade ${hasLoaded ? 'opacity-zero' : 'opacity-one'}`} src="/media/splash/scan_instructions_1.gif" alt=''/> */}
            {/* <img className={`gif2  fade ${hasLoaded ? 'opacity-one' : 'opacity-zero'}`} src="/media/splash/scan_instructions_2.gif" alt=''/> */}
            <img className={`gif1`} src="/media/splash/scan_instructions.gif" alt=''/>
            <div className={`mt-10 fade ${hasLoaded ? 'opacity-one' : 'opacity-zero'}`}>PRESS PLAY</div>
            <div className={`progressbar fade ${hasLoaded ? 'opacity-zero' : 'opacity-one'}`}>
              <ProgressBar now={progressLate} />
            </div>
            <img onClick={handleTap} className={`play-img scale ${hasLoaded ? 'scale-one' : 'scale-zero'}`} src="/media/splash/play_button.svg" alt=''/>
            <br/>
            <img className='skanteq-logo' src="/media/splash/skanteq_logo.png" alt=''/>
          </div>
        </div>
      : isError === 'marker-not-attributed'
        ? <div style={{ color: 'white', marginTop: '100px', fontSize: '28px' }}>
            This Experience is under construction. You can enjoy it after it has been published.<br></br>
            Until then, check out <a href="https://www.scanjunkie.com" >scanjunkie.com</a>
          </div>
        : <>{isError}</>
      }

      {isScanning && !needsTap && !isLoading
      ? <div className="mindar-ui-overlay mindar-ui-scanning">
          {isError === ''
          ? <div className="scanning">
              {!allowUnanchored && (<img className="instructions" src="/media/ar/instructions.svg" alt="" />)}
              {!allowUnanchored && (<img className="frame" src="/media/ar/frame.svg" alt="" />)}
              <img src="/media/ar/hand.svg" className='hand' alt="" />
              <img className='checkImg' src="/media/ar/checkbox.svg" alt="" />
              <img className='markerImg' src={markerImg} alt="" />
            </div>
          : <div className="scanning">
              <div style={{ color: 'white', marginTop: '100px', fontSize: '28px' }}>{isError}</div>
            </div>
          }
        </div>
      : <div style={{ textAlign: 'center' }} className="scanning">
          <div style={{ color: 'white', margin: '30px', fontSize: '28px' }}>{isError}</div>
        </div>
      }

      {/* Use For Animation Reference
      <img style={{ height: '100%', opacity: firstImageOpacity, transition: 'opacity 0.5s ease-in-out' }} src="/media/splash/loading.svg" alt="" />
      <div style={{ zIndex: 1000, position: 'absolute', top: '50%', width: '100%' }}>
        <ProgressBar now={progressLate} />
      </div>
      */}
    </>
  )
})

export default StatusOverlay